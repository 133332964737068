<template>
  <div class="chart-wrap">
    <div class="chart-card">
      <div class="chart-card__header">
        <h2>{{ $t("message.expence") }}</h2>
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $t(`message.${selected_item}`)
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in dateItems"
              @click.native="selected_item = item"
              :key="item"
              >{{ $t(`message.${item}`) }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <apexchart
          ref="chart"
          type="area"
          height="350"
          :options="chartOptions"
          :series="series"
        >
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["data", "active_filter"],
  data() {
    return {
      show: false,
      series: [],
      selected_item: "daily",
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
      ],
      chartOptions: {
        theme: {
          mode: "light",
        },
        chart: {
          background: "transparent",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "smooth",
          dashArray: [0, 8, 0],
        },
        title: {
          text: "",
          align: "left",
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            color: "#9ca3af",
          },
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              ""
            );
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
            {
              name: "Profit",
              data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
              fill: {
                type: "none",
              },
            },
          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#d1d5db", "#0ea5e9", "#8b5cf6"],
      },
    };
  },
  watch: {
    data: function (newVal, oldVal) {
      this.setData(newVal, this.active_filter);
    },
    active_filter: function (newVal, oldVal) {
      this.setData(this.data, this.active_filter);
    },
    selected_item: function (val) {
      this.setData(this.data, val);
    },

    mode(bool) {
      this.updateChart(bool);
    },
  },
  mounted() {
    this.setData(this.data, "mothly");
    this.updateChart(this.mode);
  },
  computed: {
    ...mapGetters({
      paymentTypes: "paymentTypes/list",
      mode: "theme/MODE",
    }),
  },
  methods: {
    setData(val, active_filter) {
      if (active_filter != "totaly" && active_filter != "filter") {
        this.series = [];
        this.statistic = [];
        this.chartOptions.xaxis.categories = [];
        const chart = this.$refs.chart;
        for (const key in val) {
          const element = val[key];

          let name = this.paymentTypes.find((paymentTypesitem) => {
            return paymentTypesitem.id == key;
          })
            ? this.paymentTypes.find((paymentTypesitem) => {
                return paymentTypesitem.id == key;
              }).name
            : "";

          this.series.push({
            name: name,
            data: val[key][active_filter].map((item) => parseFloat(item.summ)),
            fill: {
              type: "none",
            },
          });
          chart.updateOptions({
            xaxis: {
              categories: element[active_filter].map((item) => item.data),
            },
          });
        }
      }
    },

    updateChart(bool) {
      this.$refs.chart.updateOptions({
        theme: {
          mode: bool ? "dark" : "light",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  background: #e5e7eb;
  padding: 4px;
  border-radius: 10px;
}

.chart-card {
  background: #fff;
  border-radius: 8px;

  // chart-card__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e7eb;
    padding: 12px 8px;
    margin-bottom: 16px;

    h2 {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>

<style lang="scss">
.chart-card__header .el-dropdown-link {
  margin: 0;
  border: none;
}
</style>