<template>
  <div class="balans-card">
    <span class="balans-card__icon" :style="{ background: color }">
      <i :class="icon"></i>
    </span>
    <div style="width: 100%">
      <div class="balans-card__box">
        <span class="balans-card__title">{{ title }}</span>
        <div class="balans-card__nums">
          <h3 class="balans-card__total">{{ formatNumber(total) }}</h3>
          <p :style="{ color: percent >= 0 ? '#10b981' : '#ef4444' }">
            {{ percent > 0 ? "+" : "" }}{{ percent }}%
          </p>
        </div>
        <!-- <img :src="require(`@/assets/${chart}.png`)" alt=""> -->
      </div>
      <!-- <div class="balans-card__box">
                <p></p>
                <router-link class="balans-card__link" :style="{ color: color }" to="#">View All <i
                        class="fa-solid fa-arrow-right"></i></router-link>
                <span  class="balans-card__percent" :style="{ color: percent >= 0 ? '#10b981' : '#ef4444' }">
                    {{ percent > 0 ? "+" : "" }}{{ percent }}%
                </span>
            </div> -->
    </div>
  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber.js";
export default {
  props: {
    icon: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    color: {
      type: String,
      default: () => "",
    },
    chart: {
      type: String,
      default: () => "",
    },
    total: {
      type: Number,
      default: () => 0,
    },
    percent: {
      type: [Number, String],
      default: () => 0,
    },
    link: {
      type: String,
      default: () => "/",
    },
  },
  methods: {
    formatNumber,
  },
};
</script>

<style lang="scss" scoped>
.balans-card {
  display: flex;
  gap: 1.5rem;
  padding: 24px;
  border-radius: 8px;
  background: #fff;

  // card__icon
  &__icon {
    display: block;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #fff;
    background: #8b5cf6;
    display: grid;
    place-content: center;
    font-size: 13px;
  }

  // balans-card__box
  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 2rem;
    }
  }

  // balans-card__title
  &__title {
    color: #9ca3af;
    max-width: 200px;
  }

  // balans-card__total
  &__total {
    color: #444;
  }

  // balans-card__link
  &__link {
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    gap: 8px;

    i {
      transition: all 0.25s ease-in-out;
    }

    &:hover i {
      transform: translateX(5px);
    }
  }

  // balans-card__percent
  &__percent {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 700;

    span {
      color: #999;
      font-weight: 400;
    }
  }
}
</style>
